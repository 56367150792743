'use strict'
/**
 * Helpers
 *
 * how to remember the names:
 * $            -> same as jquery, but can also return null
 * $$           -> if you want to avoid nulls, use this, return NodeList that can be empty
 * $$$          -> if you don't want to deal with NodeList weird api, just want a list
 *                 give a callback and this will walk over all elements found
 * $subscribe   -> subscribe, basically the same as above but for events on elements
 * $on          -> delegated subscribe, uses the default walk pattern of the event (enter, then leave)
 *                 which means that the body receives all events first (unless we ask for only bubbling or we are in <=IE9)
 *                 where we can ask the target if it fits the selector without first having to wait for page load
 *                 and it also works with nodes in the DOM that were attached dynamically, such as React's own nodes.
 */

export function ready(fn) {
  if (
    // @ts-ignore
    document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading'
  ) {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

/**
 * Select
 * @param {String} selector Selector string for the target
 * @param {Element} [parent] Root nood under which to search, by default it's document
 * @returns {Element|null}
 */
export function $(selector, parent) {
  return (parent || document).querySelector(selector)
}
/**
 * Select, Search
 * @param {String} selector Selector string for the target
 * @param {Element} [parent] Root nood under which to search, by default it's document
 * @returns {NodeList}
 */
export function $$(selector, parent) {
  return (parent || document).querySelectorAll(selector)
}

export function $nl2arr(nodeList) {
  return Array.prototype.slice.call(nodeList)
}

/**
 * Select, Search, separately callback
 * @param {String} selector Selector string for the target
 * @param {function(Element, number, Array.<Element>):void} cb Callback for the event
 * @param {Element} [parent] Root nood under which to search, by default it's document
 * @returns {void}
 */
export function $$$(selector, cb, parent) {
  return $nl2arr($$(selector, parent)).forEach(cb)
}

/**
 * Subscribe to events on selector elements under parent elements
 * @param {String} selector Selector string for the target
 * @param {String} eventName Event.type which is a DOMString. But how to include this information in jsdoc?
 * @param {function(Event):void} cb Callback for the event
 * @param {Element} [parent] Root nood under which to search, by default it's document
 * @returns {void}
 */
export function $subscribe(selector, eventName, cb, parent) {
  //we expect dom to be loaded here
  //so if we can't find the elements it means that the callbacks
  //are not expected to be invoked
  $$$(
    selector,
    function(el) {
      el.addEventListener(eventName, cb)
    },
    parent
  )
}

/**
 * On specific events check if the target matches predefined selector and callback if so
 * @param {String} parent Capturing element, usually body is the safe bet or ':root'
 * @param {Array.<String>} selectors List of selectors for the target
 * @param {String} eventName Event.type which is a DOMString. But how to include this information in jsdoc?
 * @param {function(Event):void} cb Callback for the event
 * @param {Element} [parentParent]  If this is given, the final call will be something like `parentParent.querySelectorAll(parent).addEventListener`
 * @returns {void}
 */
export function $on(parent, selectors, eventName, cb, parentParent) {
  // for cases when the dom is not loaded
  // or the dom is changed after load
  // we use event delegation
  $$$(
    parent,
    function(el) {
      el.addEventListener(
        eventName,
        function(ev) {
          if (
            ev.target &&
            // @ts-ignore
            selectors.some(function(selector) {
              return ev.target.matches(selector)
            })
          ) {
            cb(ev)
          }
        },
        { capture: false }
      )
    },
    parentParent
  )
}

export function showHideElement(el, show) {
  if (el) {
    if (show) {
      var pdv = el.dataset._previousDisplayValue
      if (!pdv || pdv == 'none') {
        pdv = 'block'
      }
      el.style.display = pdv
    } else {
      el.dataset._previousDisplayValue = el.style.display
      el.style.display = 'none'
    }
  }
}

export function transitionOff(el, ms, activeClass) {
  activeClass = activeClass || 'is-visible'
  if (ms) {
    el.style.transitionDuration = ms + 'ms'
  }
  el.classList.remove(activeClass)
}

export function transitionOn(el, ms, activeClass) {
  activeClass = activeClass || 'is-visible'
  if (ms) {
    el.style.transitionDuration = ms + 'ms'
  }
  el.classList.add(activeClass)
}
